import React, { Component } from 'react';
import myPdf from '../../documents/Harshad_Gillurkar.pdf';

class CVComponent extends Component {
  render() {
    return (
      <main role="main" className="inner cover transbox">
          <h1 className="cover-heading">Please connect with me &#64; harshadsph@gmail.com</h1>
          <p className="lead">Thank you for downloading the resume</p>
          <p className="lead">
            <a href={myPdf} target="_blank" className="btn btn-lg btn-secondary" >download</a>
          </p>
        
      </main>
    );
  }
}

export default CVComponent;