import React from "react";
import image from '../../images/harshad.jpeg';
import linkedin from '../../images/linkedin.png';
import {withRouter} from 'react-router-dom';

class ProfileComponent extends React.Component {

    handleClick = () =>{
        const { history } = this.props;
        history.push('/experiences');
    };

    render() {
        return (
            <main role="main" className="inner cover transbox">
                <h1 className="cover-heading">Profile</h1>
                <p className="lead">Technical manager with a passion for optimizing processes to achieve efficiency and effectiveness</p>
                <hr />
                <div className="ml-6 mr-6">
                    <div className="row">
                        <div className="col-sm">
                            <h3>About Me</h3>
                            <p className="lead text-left">Dynamic and motivated professional with a proven record of generating and building relationships, managing projects from concept to completion, designing educational strategies, and coaching individuals to success. Skilled in building cross-functional teams, demonstrating exceptional communication skills, and making critical decisions during challenges. Adaptable and transformational leader with an ability to work independently, creating effective presentations, and developing opportunities that further establish organizational goals.</p>
                        </div>
                        <div className="col-sm justify-content-center align-self-center"><img src={image} width="250" className="img-fluid img-thumbnail" /></div>
                        <div className="col-sm">
                            <h3>Details</h3>
                            <p className="lead text-left font-weight-bold"> Name: </p>
                            <p className="text-left">Harshad Gillurkar</p>
                            <hr />
                            <p className="lead text-left font-weight-bold"> Age: </p>
                            <p className="text-left">39 Years</p>
                            <hr />
                            <p className="lead text-left font-weight-bold"> Follow Me </p>
                            <p className="text-left"><a href="https://www.linkedin.com/in/harshad-g-61233a12b/" target="_blank"><img src={linkedin} /></a></p>
                            <hr />
                        </div>
                    </div>
                </div>
                <hr />
                <p className="lead">
                    <a href="#" className="btn btn-lg btn-secondary" onClick={this.handleClick} >Experience</a>
                </p>
            </main>
        );
    }
}

export default withRouter(ProfileComponent);