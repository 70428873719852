const data = {
  GOODREADS:[
    {
      image: 'https://www.scaledagileframework.com/wp-content/uploads/2019/08/Organizational_Agility_5.0_Nav_Icon.png',
      title: 'Organization Agility',
      text: 'Agility is the ability to adapt and respond to change … agile organizations view change as an opportunity, not a threat.',
      link: 'https://www.scaledagileframework.com/organizational-agility/',
      type: 'Information '
    },
    {
    image: 'https://img-d02.moneycontrol.co.in/images/common/mc_logo_200x200.png',
    title: 'Business News, Stock/Share Market Investment,Financial News - Moneycontrol',
    text: 'Moneycontrol is India\'s leading financial information source for Live Stock Price, Stock/Share Trading news',
    link: 'https://www.moneycontrol.com/',
    type: 'Finance '
  },{
    image: 'https://containerjournal.com/wp-content/uploads/2019/01/Kubernetes-Vs-Docker.jpg',
    title: 'Kubernetes vs. Docker: A Primer - Container Journal',
    text: '“Kubernetes or Docker?” in itself is rather absurd, like comparing apples to oranges. But they have a symbiotic relationship.',
    link: 'https://containerjournal.com/topics/container-ecosystems/kubernetes-vs-docker-a-primer/',
    type: 'Technology '
  },{
        image: 'https://thumbor.forbes.com/thumbor/960x0/https%3A%2F%2Fspecials-images.forbesimg.com%2Fdam%2Fimageserve%2F1182226055%2F960x0.jpg%3Ffit%3Dscale',
        title: '13 Tech Experts Predict The Next Big Trend In Software Development',
        text: 'Members of Forbes Technology Council share their predictions for upcoming trends in software development.',
        link: 'https://www.forbes.com/sites/forbestechcouncil/2019/10/21/13-tech-experts-predict-the-next-big-trend-in-software-development/#49d1b24a2b35',
        type: 'Technology '
      },{
        image: 'https://miro.medium.com/max/1200/1*9wKp6_VyXtqRpXGoPG26rg.jpeg',
        title: 'Two clouds in practice: a comparison of SAP Commerce Cloud v1 and v2',
        text: 'Experiences and best practices of the two very different SAP Commerce Clouds',
        link: 'https://medium.com/@marko.ullgren/two-clouds-in-practice-a-comparison-of-sap-commerce-cloud-v1-and-v2-592077658e01',
        type: 'Technology '
      },{
        image: 'https://s27468.pcdn.co/wp-content/uploads/2018/06/main_home_page_hero_image.jpg',
        title: 'World Airline Awards',
        text: 'The World Airline Awards from Skytrax are coveted Quality accolades for the airline industry, and a global benchmark of airline excellence.',
        link: 'https://www.worldairlineawards.com/',
        type: 'Information '
      },{
        image: 'https://inteng-storage.s3.amazonaws.com/images/sizes/blowing-inventions_md.jpg',
        title: '15 Inventions That Will Make Your 2019 a Lot More Interesting',
        text: 'Here are some of the most interesting products and inventions to come out in years to get your 2019 rolling.',
        link: 'https://interestingengineering.com/15-inventions-that-will-make-your-2019-a-lot-more-interesting',
        type: 'Invention '
      },
      {
        image: 'https://blog.iamsuleiman.com/wp-content/uploads/2017/02/loading_spinner_skeleton_screen_header.png',
        title: 'Stop Using Loading Spinner, There’s Something Better',
        text: 'Stop using those boring loading spinners in your user interfaces. Seriously. It\'s killing your app experience more than you know',
        link: 'https://blog.iamsuleiman.com/stop-using-loading-spinner-theres-something-better/',
        type: 'Technology'
      },
      {
        image: 'https://daqxzxzy8xq3u.cloudfront.net/wp-content/uploads/2019/04/21032431/redux-cover-imgage.jpg',
        title: 'How to add Redux to Create React App',
        text: 'Using Redux will help centralize your state data, debug, and make your code more predictable.',
        link: 'https://blog.linguinecode.com/post/setup-redux-react',
        type: 'Technology'
      }]
};


export default data;