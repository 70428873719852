import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import HeaderComponent from './components/navigation/HeaderComponent';
import ContentComponent from './components/navigation/ContentComponent';
import FooterComponent from './components/navigation/FooterComponent';
import ProfileComponent from './components/navigation/ProfileComponent';
import CVComponent from './components/navigation/CVComponent';
import ExperiencesComponent from './components/navigation/ExperiencesComponent';
import GoodReads from "./components/navigation/GoodreadsComponent";
import { createBrowserHistory } from 'history';

class App extends React.Component{
  
  render() {
    const history = createBrowserHistory();
    const path = (/#!(\/.*)$/.exec(history.location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }
    
    return (
      <BrowserRouter>
      <div className="d-flex h-100 p-3 mx-auto flex-column">
          <HeaderComponent />
            <Fragment>
            <Switch>
              <Route
                path='/profile'
                render={props => (
                  <ProfileComponent/>
                )}
              />
               <Route
                path='/resume'
                render={props => (
                  <CVComponent/>
                )}
              />
              <Route
                path='/experiences'
                render={props => (
                  <ExperiencesComponent/>
                )}
              />
              <Route
                path='/good-reads'
                render={props => (
                  <GoodReads/>
                )}
              />
              
              <Route
                path='/'
                render={props => (
                  <ContentComponent/>
                )}
              />
              
              <Redirect to="/404" />
            </Switch>
            </Fragment>
          <FooterComponent />
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
