import { combineReducers, createStore, applyMiddleware } from "redux";
// data
import data  from "./data";
// thunk
import thunk from "redux-thunk";

// keys used for storing in localStorage
export const reducers = {
  data: data
};

const rootReducer = combineReducers(reducers);

// initial state from localStorage, overrides default reducer values
// if undefined, default values from reducers are used
const store = createStore(rootReducer, {}, applyMiddleware(thunk));

export default store;
