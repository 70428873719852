import React, { Component } from 'react';
import location from '../../images/location.png';

class ExperiencesComponent extends Component {
    render() {
        return (
            <div>
                <main role="main" className="inner cover transbox mt-5">
                <h1 className="cover-heading">Experiences</h1>
                <hr />
                <div className="ml-6 mr-6">
                    <div className="row mt-3 bg-info">
                        <div className="col-md-4">
                            <h5 className="text-left">Regional Service Manager</h5>
                            <p className="text-left">Oct 2016 - Till Now (&#64;Singapore)</p>
                            

                        </div>
                        <div className="col-md-8">
                            <p className="text-left">Being a Regional Digital IT Manager, mainly responsible for developing and managing web, mobile, and e-commerce applications for Asia Pacific countries. currently managing a diverse team in building high-quality reusable software. Under my leadership, we launch Hybris, Sitecore, and Drupal global programs for Asia Pacific countries</p>
                        </div>
                    </div>

                    <div className="row mt-5 bg-info">
                        <div className="col-md-4">
                            <h5 className="text-left">Regional Technical Lead</h5>
                            <p className="text-left">Apr 2015 - Sept 2016 (&#64;Singapore)</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text-left"> Joined as a Technical Lead for Drupal. During lead 
                            tenure we successfully deliver over 50+ brand websites to consumer. Within one and half 
                            year of time-frame under my supervision there are multiple in-house and vendor team working in China 
                            and India</p>
                        </div>
                    </div>

                    <div className="row mt-5 bg-info">
                        <div className="col-md-4">
                            <h5 className="text-left">System Analyst</h5>
                            <p className="text-left">Sept 2013 - March 2015 (&#64;Singapore)</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text-left"> I played as an subject matter expert role for building the 
                            company's internal power monitoring portal which used across all global data centres.
                            Power Monitoring Portal constructed using Angular, JAVA Play Framework and integrated 
                            with Big Data
                            </p>
                        </div>
                    </div>

                    <div className="row mt-5 bg-info">
                        <div className="col-md-4">
                            <h5 className="text-left">Senior Vignette Consultant</h5>
                            <p className="text-left">April 2011 - Sept 2013 (&#64;Singapore)</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text-left"> Being a consultant to SPH, employed for niche skilled CMS vignette 
                            and Drupal development for Straight Times and AsiaOne.com. Working with business for 
                            developing new requirement and enhancement</p>
                        </div>
                    </div>

                    <div className="row mt-5 bg-info">
                        <div className="col-md-4">
                            <h5 className="text-left">Senior Software Engineer</h5>
                            <p className="text-left">Oct 2007 - Apr 2011 (&#64;Johannesburg, South Africa)</p>
                        </div>
                        <div className="col-md-8">
                            <p className="text-left">  Niched skills in Vignette CMS development helps the  business to
                            develop their new personalized retail portal of Bank. I am
                            responsible for developing and enhancing the retail portal of ABSA within
                            the team of 6 people</p>
                        </div>
                    </div>
                </div>
                </main>
            </div>
        );
    }
}

export default ExperiencesComponent;