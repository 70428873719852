import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const GoodReads = ({
    goodReads
  }) => {
    return (
        <main role="main" className="inner cover transbox mt-5">
        <Container fluid>
        <Row>
        {goodReads.map(sh => (
                <Col xs={12} md={3}>
                <Card className="cardBg">
                <Card.Body>
                    <span className="badge badge-danger float-right">{sh.type}</span>
                    <Card.Title className="mt-2">{sh.title} </Card.Title>
                    <Card.Img className="cardImg" variant="top" src={sh.image} />
                    <Card.Text>{sh.text}</Card.Text>
                    <a href={sh.link} target="_blank"><Button variant="info">Explore more</Button></a>
                </Card.Body>
                </Card>
                </Col>
           
        ))}
        </Row>
        </Container>
        </main>
    );
  };

const connected = connect(
    state => ({
      goodReads: state.data.GOODREADS
    })
  )(GoodReads);

export default withRouter(connected);
