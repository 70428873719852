import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './HeaderComponent.css';
import {withRouter} from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import myPdf from '../../documents/Harshad_Gillurkar.pdf';

class HeaderComponent extends React.Component {

    render() {
        const {
            history
          } = this.props;
        return (
            <header className="masthead mb-auto">
                <div className="inner container-fluid">
                    <Navbar collapseOnSelect expand="lg" variant="dark">
                    <Navbar.Brand onClick={() => history.push("/")}><h3 className="masthead-brand">Harshad Gillurkar</h3></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto nav nav-masthead">
                            <NavLink exact to="/" activeClassName="active" className="nav-link">Home</NavLink>
                            <NavLink exact to="/profile" activeClassName="active" className="nav-link">Profile</NavLink>
                            <NavLink exact to="/experiences" activeClassName="active" className="nav-link">Experience</NavLink>
                            <a href={myPdf} target="_blank" activeClassName="active" className="nav-link">Resume</a>
                        </Nav>
                        <Nav className="nav nav-masthead justify-content-center">
                            <a href="https://www.iss.nus.edu.sg/graduate-programmes/programme/detail/master-of-technology-in-digital-leadership" target="_blank" className="nav-link">NUS MTECH Digtial Leadership (DL) candidate </a>
                        </Nav>
                    </Navbar.Collapse>
                    </Navbar>
                </div>
                <hr />
          </header>
        );
    }
}
export default withRouter(HeaderComponent);
