import React from "react";
import {withRouter} from 'react-router-dom';
import './ContentComponent.css';


class ContentComponent extends React.Component{
  
    handleClick = () =>{
        const { history } = this.props;
        history.push('/profile');
    };

    render(){
        return(
            <main role="main" className="inner cover transbox">
            <h1 className="cover-heading">Passionately Agile | Lean | Design Thinking | User Centric</h1>
            <h1 className="cover-heading"> </h1>
            <div className="bg-info ml-6 mr-6">
                <p className="lead">Motivated professional with a proven record of building relationships, managing projects from concept to completion, coaching individuals to success. An adaptable and transformation leader with an ability to work independently and with the teams, creating effective presentations, and developing opportunities that further establish organizational goals. </p>
            </div>
            <p className="lead">
              <a href="#" className="btn btn-lg btn-secondary" onClick={this.handleClick}>Explore more</a>
            </p>
           
            
          </main>
        );
    }
}
export default withRouter(ContentComponent);