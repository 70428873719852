import React from "react";
import jj from "../../images/org/jj.png";
import equinix from "../../images/org/equinix.png"
import sph from "../../images/org/sph.png"
import lnt from "../../images/org/lnt.png"
import absa from "../../images/org/absa.png"
import nic from "../../images/org/nic.png"
class FooterComponent extends React.Component {
    render() {
        return (
            <footer className="mastfoot mt-auto">
                <div className="inner">
                    <p>	&#9400; Harshad Gillurkar. All rights reserved</p>
                    <div className="org-info ml-6 mr-6 cover">
                        My Companies -
                        <img src={jj} className="org-logo-jj" />
                        <img src={equinix} className="org-logo-equinix" />
                        <img src={sph} className="org-logo-sph" />
                        <img src={lnt} className="org-logo-lnt" />
                        <img src={absa} className="org-logo-absa" />
                        <img src={nic} className="org-logo-nic" />
                    </div>
                </div>
            </footer>
        );
    }
}
export default FooterComponent;